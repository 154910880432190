import { useEffect, useState } from 'react'
import { type CompletionStatus, type Deployment } from 'types'

interface DeploymentLineProps {
  deployment: Deployment
}

export const DeploymentLine = ({ deployment }: DeploymentLineProps) => {

  const [deploymentStatus, setDeploymentStatus] = useState<CompletionStatus|undefined>(undefined)

  useEffect(() => {
    let lastTimestamp = -1
    if(deployment?.stages && deployment?.stages.length > 0 ){
      deployment.stages.forEach((stage) => {
         if(stage.timestamp > lastTimestamp){
           lastTimestamp = stage.timestamp
           setDeploymentStatus(stage.completionStatus)
         }
      })
    }
  }, [deployment])

  return (
    <div className={'flex items-center p-4 bg-white shadow rounded mb-4'} >
      <div className={'flex-grow'}>
        <div className="flex justify-between mb-2">
          <div className="font-bold">
            {deployment.extensionPackId} - {deployment.globalVersion} <br/>
            Deployed by: {deployment.user}
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            <div className="text-gray-600 mb-2">{deployment.targetInstanceId}</div>
            <div className="text-gray-600 mb-2">{deploymentStatus ?? 'Unable to resolve current completion status.'}</div>
          </div>
        </div>
      </div>
    </div>
  )
}