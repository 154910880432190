import { type DeploymentDTO, type DeploymentStage, type Deployment, type DeploymentStageDTO, CompletionStatus } from 'types'
import { formatUnixTimestamp } from 'utils/DateTools'

export const fromDeploymentDTO = (deployment: DeploymentDTO): Deployment => ({
    id: deployment.id,
    extensionPackId: deployment.extensionPackId,
    globalVersion: deployment.globalVersion,
    targetInstanceId: deployment.targetInstanceId,
    targetInstanceName: deployment.targetInstanceName,
    targetProfile: deployment.targetProfile,
    user: deployment.user,
    stages: deployment.stages.map(fromDeploymentStageDTO)
})

const fromDeploymentStageDTO = (stage: DeploymentStageDTO): DeploymentStage => ({
    timestamp: stage.timestamp,
    timestampFormatted: formatUnixTimestamp(stage.timestamp),
    name: stage.name,
    completionStatus: CompletionStatus[stage.completionStatus as keyof typeof CompletionStatus],
    message: stage.message
})
