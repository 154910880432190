import { type MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks'
import { fromDeploymentDTO } from 'mapper'
import { mainSplitApi } from 'redux/store/mainSplitApi'
import {
  CompletionStatus,
  type Deployment,
  type DeploymentCreationRequest,
  type DeploymentCreationResponse,
  type DeploymentDTO
} from 'types'
import { InitiateSSE } from 'utils/InitiateSSE'

const handleCache = (cache: MaybeDrafted<Deployment[]>, data: DeploymentDTO) => {
  const deployment: Deployment = fromDeploymentDTO(data)
  const index = cache.findIndex(d => d.id === deployment.id)
  if (index === -1) {
    cache.unshift(deployment)
  } else {
    cache[index] = deployment
  }
  return cache
}

export const deploymentApi = mainSplitApi.injectEndpoints({
  endpoints: builder => ({
    getDeployments: builder.query<Deployment[], void>({
      queryFn () {
        return { data: [] }
      },
      async onCacheEntryAdded (
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const abortCtrl = new AbortController() // Create an abort controller for the SSE connection
        try {
          const handleEvent = (data: DeploymentDTO) => {
            updateCachedData(draft => {
              return handleCache(draft, data)
            })
          }
          InitiateSSE(handleEvent, abortCtrl, 'deployments')
          await cacheDataLoaded
        } catch {
          console.log('Unexpected failure')
        }
        await cacheEntryRemoved
        abortCtrl.abort()
      }
    }),
    getLatestDeployments: builder.query({
      queryFn: async () => {
        // Mock response
        return { data: mockDeployments }
      }
    }),
    getDeployment: builder.query<Deployment, string>({
      query: (id) => `deployments/${id}`,
      transformResponse: (response: DeploymentDTO) => {
        const deployment = fromDeploymentDTO(response);
        return deployment;
      }
    }),
    submitDeploy: builder.mutation<DeploymentCreationResponse, DeploymentCreationRequest>({
      query: body => ({
        url: '/deployments',
        method: 'POST',
        body
      })
    })
  }),
  overrideExisting: false
})

export const mockDeployments: Deployment[] = [
  {
    id: '1',
    extensionPackId: 'extensionPack1',
    globalVersion: 'v1.0',
    targetInstanceId: 'd4e1b6d0-9194-4312-a540-e8924d61affe',
    targetInstanceName: 'test-instance-1',
    targetProfile: 'test-profile-1',
    user: 'DespicableMe',
    stages: [
      {
        timestamp: 5,
        timestampFormatted: '5',
        name: 'last stage',
        completionStatus: CompletionStatus.COMPLETED,
        message: ''
      },
      {
        timestamp: 4,
        timestampFormatted: '4',
        name: 'first stage',
        completionStatus: CompletionStatus.IN_PROGRESS,
        message: ''
      }]
  },
  {
    id: '2',
    extensionPackId: 'extensionPack2',
    globalVersion: 'v2.0',
    targetInstanceId: '30032bc4-f047-446e-a60d-b76e2fd2b374',
    targetInstanceName: 'test-instance-2',
    targetProfile: 'test-profile-2',
    user: 'Minion1',
    stages: []
  },
  {
    id: '3',
    extensionPackId: 'extensionPack3',
    globalVersion: 'v3.0',
    targetInstanceId: 'bae482c5-8bc6-4e66-b77c-85117789d93a',
    targetInstanceName: 'test-instance-2',
    targetProfile: 'test-profile-2',
    user: 'Minion5',
    stages: []
  }
]

export const { useGetDeploymentsQuery, useGetLatestDeploymentsQuery, useGetDeploymentQuery, useSubmitDeployMutation } =
  deploymentApi
