import { Card, Icon, TextField } from '@nike/eds'
import { useGetDeploymentsQuery } from 'api/deployment'
import { useGetInstancesQuery } from 'api/instance'
import { checkDeploymentRole } from 'auth/authentication'
import { Paging } from 'components/paging'
import { DeployLine } from 'pages/deploy/components'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, type RootState } from 'redux/store'
import { type Deployment } from 'types'

import { NewDeployForm } from './components/NewDeployForm'

const userSelector = (state: RootState) => state.user

export const Deploy = () => {
  const { data: instances, isLoading, isError } = useGetInstancesQuery()
  const { data: deployments } = useGetDeploymentsQuery()
  const { user } = useAppSelector(userSelector)
  const navigate = useNavigate()

  const [searchValue, setSearchValue] = useState<string>('')
  const [showDeployments, setShowDeployments] = useState<Deployment[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [itemOffset, setItemOffset] = useState<number>(0)
  const itemsPerPage = 10

  const showDeployment = useCallback((deployment: Deployment) => {
    navigate(`/deploy/${deployment.id}`, { state: { deployment } })
  }, [])

  useEffect(() => {
    const toShowDeployments = deployments?.map(deployment => {
      const matchingInstance = instances?.find(instance => instance.id === deployment.targetInstanceId);
      return {
        ...deployment,
        targetInstanceName: matchingInstance?.name ?? 'Unknown Instance'
      }
    }).filter(deployment => filterBy(deployment, searchValue));
    if (toShowDeployments !== undefined) {
      setShowDeployments(toShowDeployments.slice(itemOffset, itemOffset + itemsPerPage))
      setPageCount(Math.ceil(toShowDeployments.length / itemsPerPage))
    }
  }, [deployments, instances, searchValue, itemOffset])

  return (
    <div className="md:w-4/5 w-full m-auto mt-2">
      <div className="flex items-center place-content-between mb-4">
        <h1 className="py-5 eds-type--title-1">Deploy</h1>
      </div>
      {checkDeploymentRole(user) && <NewDeployForm instances={instances} isLoading={isLoading} isError={isError}/>}
      <br />
      <hr />
      <div className="flex flex-col mt-6">
        <div className='mb-8'>
          <TextField
            id='deployments'
            value={searchValue}
            onChange={e => { setSearchValue(e.target.value) }}
            label=''
            beforeSlot={<Icon name='Search' />}
            afterSlot={<Icon name='Close' onClick={() => { setSearchValue('') }} />}
          />
        </div>
        {showDeployments != null && showDeployments.length > 0
          ? (<>
            {showDeployments.map((deploy: Deployment) => (
              <div key={`div_${deploy.id}`} onClick={() => { showDeployment(deploy) }}>
                <DeployLine key={deploy.id} deploy={deploy} />
              </div>
            ))}
            <div>
              {deployments != null && (<Paging items={deployments} itemsPerPage={itemsPerPage} setItemOffset={setItemOffset} pageCount={pageCount} />)}
            </div>
          </>)
          : <Card className={'w-full border'}>
            <span className={'text-center'}>No deployments found</span>
          </Card>
        }
      </div>
    </div>
  )
}

const createFilters = (
  searchValue: string
): Array<(deployment: Deployment) => boolean> => [
    deployment => deployment.extensionPackId.toLowerCase().includes(searchValue.toLowerCase()),
    deployment => deployment.globalVersion.toLowerCase().includes(searchValue.toLowerCase()),
    deployment => deployment.targetInstanceId.toLowerCase().includes(searchValue.toLowerCase()),
  ]

export const filterBy = (toFilter: Deployment | undefined, searchValue: string): boolean => {
  if (toFilter !== undefined && searchValue !== '') {
    const filters = createFilters(searchValue);
    return filters.some(filter => filter(toFilter));
  }
  return true;
}
