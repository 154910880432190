import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { type Deployment } from 'types';

const deploymentAdapter = createEntityAdapter<Deployment>({
    selectId: deployment => deployment.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id)
})

export const deploymentSlice = createSlice({
    name: 'deployment',
    initialState: deploymentAdapter.getInitialState(),
    reducers: {
        deploymentReceived: deploymentAdapter.setOne,
        deploymentsReceived: deploymentAdapter.setAll
    }
})

export const { deploymentReceived, deploymentsReceived } = deploymentSlice.actions
