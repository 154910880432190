import { Icon, Tooltip } from '@nike/eds';
import { ProgressBar } from 'components/progressbar';
import { SpinnerTypes, StatusSpinner } from 'components/status-spinner';
import { useEffect, useState } from 'react';
import { CompletionStatus, type Deployment } from 'types';


interface DeployLineProps {
    key: string;
    deploy: Deployment;
};

export const DeployLine = ({ deploy }: DeployLineProps) => {
    const [deployInfo, setDeployInfo] = useState<Deployment>({
        id: '',
        extensionPackId: '',
        globalVersion: '',
        targetInstanceId: '',
        targetInstanceName: '',
        targetProfile: '',
        user: '',
        stages: []
    })
    const [hasStages, setHasStages] = useState<boolean>(false)

    useEffect(() => {
        setHasStages(deploy && deploy.stages && deploy.stages.length > 0)
        setDeployInfo(deploy)
    }, [deploy])

    const renderStatusIcon = (completionStatus: CompletionStatus) => {
        switch (completionStatus) {
            case CompletionStatus.NOT_STARTED:
                return (
                    <Tooltip bodySlot={'Not started'} placement={'left'}>
                        <StatusSpinner type={SpinnerTypes.NOT_STARTED} />
                    </Tooltip>
                )
            case CompletionStatus.IN_PROGRESS:
                return (
                    <Tooltip bodySlot={'In progress'} placement={'left'}>
                        <StatusSpinner type={SpinnerTypes.IN_PROGRESS} />
                    </Tooltip>
                )
            case CompletionStatus.COMPLETED:
                return (
                    <Tooltip bodySlot={'Completed'} placement={'left'}>
                        <div className="flex-none w-12 h-12 bg-green-300 flex items-center justify-center rounded-full mr-4">
                            <Icon name="Check" size="m" />
                        </div>
                    </Tooltip>
                )
            case CompletionStatus.FAILED:
                return (
                    <Tooltip bodySlot={'Failed'} placement={'left'}>
                        <div className="flex-none w-12 h-12 bg-red-300 flex items-center justify-center rounded-full mr-4">
                            <Icon name="Close" size="m" />
                        </div>
                    </Tooltip>
                )
            case CompletionStatus.INTERNAL_ERROR:
                return (
                    <Tooltip bodySlot={'Internal error'} placement={'left'}>
                        <div className="flex-none w-12 h-12 bg-orange-300 flex items-center justify-center rounded-full mr-4">
                            <Icon name="Alert" size="m" />
                        </div>
                    </Tooltip>
                )
        }
    }

    const checkValueIsPresent = (value: string) => {
        return value === undefined ? '❌' : value
    }

    const completedSteps = deployInfo.stages.filter(stage => stage.completionStatus === CompletionStatus.COMPLETED).length
    const totalSteps = deployInfo.stages.length
    const progressPercent = (completedSteps / totalSteps) * 100

    return (
        <div className="flex items-center p-4 bg-white shadow rounded mb-4 cursor-pointer">
            {renderStatusIcon(hasStages ? deployInfo.stages[deployInfo.stages.length - 1].completionStatus! : CompletionStatus.NOT_STARTED)}
            <div className="flex-grow">
                <div className="flex justify-between mb-2">
                    <div className="font-bold">
                        {deployInfo.extensionPackId} - {deployInfo.user}
                    </div>
                </div>
                <div className="eds-grid eds-grid--s-cols-4 flex justify-between mb-2">
                    <div className="text-gray-600">{`Global Version: ${checkValueIsPresent(deployInfo.globalVersion)}`}</div>
                    <div className="text-gray-600">{`Timestamp: ${deployInfo.stages[0]?.timestampFormatted}`}</div>
                    <div className="text-gray-600">{`Instance: ${checkValueIsPresent(deployInfo.targetInstanceName)}`}</div>
                    <div className="text-gray-600 eds-grid--item-justify-end">{`Profile: ${checkValueIsPresent(deployInfo.targetProfile)}`}</div>
                </div>
                <>
                    <ProgressBar value={progressPercent} max={100} />
                </>
            </div>
        </div>
    )
}
