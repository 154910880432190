import { Card, Icon, Modal, Select, type SingleValue, TextField } from '@nike/eds'
import { useGetLatestDeploymentsQuery } from 'api/deployment'
import { useGetInstancesQuery } from 'api/instance'
import { useEffect, useState } from 'react'
import { showSnackbar } from 'redux/slices/snackbar.slices'
import { dispatch } from 'redux/store'
import { type CompletionStatus, type Deployment, type Instance } from 'types'

import { DeploymentLine } from './components'


export const DeploymentState = () => {
  const { data: instances, isLoading: isInstanceDataLoading, isError: isInstanceDataLoadError } = useGetInstancesQuery()
  const emptySelectOption = { label: '', value: '' }
  const [ instanceOptions, setInstanceOptions] = useState([emptySelectOption])
  const [selectedInstance, setSelectedInstance] = useState(emptySelectOption)
  const [searchValue, setSearchValue] = useState<string>('')
  const { data: deployments, isLoading: isDeploymentDataLoading, isError: isDeploymentDataLoadError } = useGetLatestDeploymentsQuery({})
  const [filteredDeployments, setFilteredDeployments] = useState<Deployment[]>([])
  const [selectedDeployment, setSelectedDeployment] = useState<Deployment|undefined>(undefined)

  useEffect(() => {
    if(isDeploymentDataLoading){
      return
    }
    let results = deployments
    if (selectedInstance?.value && results !== undefined) {
      results = results
        .filter(deployment => deployment.targetInstanceId === selectedInstance.value)
    }
    if (searchValue.length > 0 && results !== undefined) {
      results = results.filter(deployment =>
        deployment.id.toLowerCase().includes(searchValue.toLowerCase().trim()) ||
        deployment.extensionPackId.toLowerCase().includes(searchValue.toLowerCase().trim()) ||
        deployment.globalVersion.toLowerCase().includes(searchValue.toLowerCase().trim()) ||
        deployment.user.toLowerCase().includes(searchValue.toLowerCase().trim()))
    }
    if ( results !== undefined)
    setFilteredDeployments(results)
  },[selectedInstance, searchValue])

  useEffect(() => {
    if(deployments) {
      setFilteredDeployments(deployments)
    }
  }, [deployments])

  useEffect(() => {
    if(isInstanceDataLoading) {
      return
    }
    if(instances){
      const options : Array<{ label:string, value:string }> = []
      instances.forEach((instance: Instance) => {
        options.push({ label: instance.name, value: instance.id })
      })
      setInstanceOptions(options)
    }
  }, [instances, isInstanceDataLoading])

  useEffect(() => {
    if(isDeploymentDataLoadError){
      dispatch(showSnackbar('Error Loading Deployment data'))
    }
    if(isInstanceDataLoadError){
      dispatch(showSnackbar('Error Loading Instance data'))
    }
  }, [isDeploymentDataLoadError, isInstanceDataLoadError])

  const getDeploymentStatus = (deployment: Deployment|undefined) => {
    let lastTimestamp = -1
    let status : CompletionStatus|undefined
    if(deployment?.stages && deployment?.stages.length > 0 ){
      deployment.stages.forEach((stage) => {
        if(stage.timestamp > lastTimestamp){
          lastTimestamp = stage.timestamp
          status = stage.completionStatus
        }
      })
    }
    return status;
  }

  const handleClose = () => {
    setSelectedDeployment(undefined)
  }

  const updateSelectedInstance = (input: SingleValue<{   label: string;   value: string; }>) => {
    setSelectedInstance((input !== null) ? {
      label: input?.label,
      value: input?.value
    } : emptySelectOption)
  }

  return (
    <div className="md:w-4/5 w-full m-auto mt-2">
      <div className="flex items-center place-content-between mb-4">
        <div className="py-5 eds-type--title-1">
          Deployments EP
        </div>
      </div>
      <div className={"flex flex-row"}>
        {instanceOptions.length > 0 &&
          <div className='mb-8 mr-8 w-5/6 pr-1'>
            <Select id={'instanceConfig-selector'}
                    className={''}
                    isClearable={true}
                    label={'Instance:'}
                    value={selectedInstance}
                    onChange={(option) => {
                     updateSelectedInstance(option)
                    }} options={instanceOptions} />
          </div>
        }
        <div className="mb-8 mr-8 w-5/6 pr-1">
          <TextField
            id='extensionPacks'
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            label='Search extension packs'
            beforeSlot={<Icon name='Search' />}
            afterSlot={<Icon name='Close' onClick={() => {
              setSearchValue('')
            }} />}
          />
        </div>
      </div>
      {deployments && deployments.length > 0
        ? (
          <div className={'flex flex-wrap mb-6'}>
            {filteredDeployments.map((ep: Deployment) => (
              <div key={`div_${ep.id}`}
                   className={"basis-1/2 pr-10"}
                   onClick={() => { setSelectedDeployment(ep) } }>
                {<DeploymentLine key={ep.id} deployment={ep} />}
              </div>
            ))}
          </div>
        )
        : (
          <Card className={'w-full border'}>
            <span className={'text-center'}>No extension pack deployments to display</span>
          </Card>
        )
      }
      <Modal headerSlot={<>{selectedDeployment?.extensionPackId}</>}
             isOpen={selectedDeployment!==undefined}
             hideFade={true}
             onDismiss={handleClose}>
        <div className="bg-white p-8 rounded w-400">
          <h1 className={'my-4 eds-type--title-5'}>GlobalVersion: {selectedDeployment?.globalVersion}</h1>
          <div className="flex justify-between w-350">
            <div>
              <div className="text-gray-600 mb-2">Deployed on: {selectedDeployment?.targetInstanceId}</div>
              <div className="text-gray-600 mb-2">Deployed by: {selectedDeployment?.user}</div>
              <div className="text-gray-600 mb-2">Deployment status: {getDeploymentStatus(selectedDeployment) ?? '?'}</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}